import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step certificate`}</strong>{` -- create, revoke, validate, bundle, and otherwise manage certificates`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step certificate SUBCOMMAND [ARGUMENTS] [GLOBAL_FLAGS] [SUBCOMMAND_FLAGS]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step certificate`}</strong>{` command group provides facilities for creating
certificate signing requests (CSRs), creating self-signed certificates
(e.g., for use as a root certificate authority), generating leaf or
intermediate CA certificate by signing a CSR, validating certificates,
renewing certificates, generating certificate bundles, and key-wrapping
of private keys.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Create a root certificate and private key using the default parameters (EC P-256 curve):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate create foo foo.crt foo.key --profile root-ca
`}</code></pre>
    <p>{`Create a leaf certificate and private key using the default parameters (EC P-256 curve):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate create baz baz.crt baz.key --ca ./foo.crt --ca-key ./foo.key
`}</code></pre>
    <p>{`Create a CSR and private key using the default parameters (EC P-256 curve):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate create zap zap.csr zap.key --csr
`}</code></pre>
    <p>{`Sign a CSR and generate a signed certificate:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate sign zap.csr foo.crt foo.key
`}</code></pre>
    <p>{`Inspect the contents of a certificate:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate inspect ./baz.crt
`}</code></pre>
    <p>{`Verify the signature of a certificate:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate verify ./baz.crt --roots ./foo.crt
`}</code></pre>
    <p>{`Lint the contents of a certificate to check for common errors and missing fields:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate lint ./baz.crt
`}</code></pre>
    <p>{`Bundle an end certificate with the issuing certificate:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate bundle ./baz.crt ./foo.crt bundle.crt
`}</code></pre>
    <p>{`Convert PEM format certificate to DER and write to disk.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate format foo.pem --out foo.der
`}</code></pre>
    <p>{`Extract the public key from a PEM encoded certificate:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate key foo.crt
`}</code></pre>
    <p>{`Install a root certificate in the system truststore:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate install root-ca.crt
`}</code></pre>
    <p>{`Uninstall a root certificate from the system truststore:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate uninstall root-ca.crt
`}</code></pre>
    <h2>{`Commands`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "bundle/"
              }}>{`bundle`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bundle a certificate with intermediate certificate(s) needed for certificate path validation`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "create/"
              }}>{`create`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`create a certificate or certificate signing request`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "format/"
              }}>{`format`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`reformat certificate`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "inspect/"
              }}>{`inspect`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`print certificate or CSR details in human readable format`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "fingerprint/"
              }}>{`fingerprint`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`print the fingerprint of a certificate`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "lint/"
              }}>{`lint`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`lint certificate details`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "needs-renewal/"
              }}>{`needs-renewal`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Check if a certificate needs to be renewed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "sign/"
              }}>{`sign`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`sign a certificate signing request (CSR)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "verify/"
              }}>{`verify`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`verify a certificate`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "key/"
              }}>{`key`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`print public key embedded in a certificate`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "install/"
              }}>{`install`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`install a root certificate in the system truststore`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "uninstall/"
              }}>{`uninstall`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`uninstall a root certificate from the system truststore`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "p12/"
              }}>{`p12`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`package a certificate and keys into a .p12 file`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      